import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import fastclick from 'fastclick'
import VueLazyload from 'vue-lazyload'
import { VueCookieNext } from 'vue-cookie-next'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import Skeleton from 'vue-loading-skeleton';
import "vue-loading-skeleton/dist/style.css"


fastclick.attach(document.body)
const loadimage = require('./assets/images/asharee.jpeg')
const errorimage = require('./assets/images/asharee.jpeg')
const app = createApp(App);
    app.use(store)
    app.use(router)
    router.app = app
    app.use(VueLazyload, {
        preLoad: 1.3,
        error: errorimage,
        loading: loadimage,
        attempt: 1
    })
    app.use(VueCookieNext)
    app.use(Toast, {
        transition: "Vue-Toastification__fade",
        maxToasts: 30,
        newestOnTop: true,
        timeout: 4000,
        closeOnClick: false,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: false,
        icon: true,
        rtl: true
    })
    app.use(Skeleton)
    app.mount('#app')
